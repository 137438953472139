import Axios from "@/plugins/Axios";

export class RelatoriosService {
  async listarTurma(turma) {
    const response = await Axios()
      .get(`relatorios/listarTurma/${turma}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async boletimGeral(turma) {
    const response = await Axios()
      .get(`relatorios/boletimGeral/${turma}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatorioBNCC(matricula_id) {
    const response = await Axios()
      .get(`relatorios/relatorioBNCC/${matricula_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatorioBNCCTurma(turma_id) {
    const response = await Axios()
      .get(`relatorios/relatorio-bncc/${turma_id}/turma`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
  }

  async boletimIndividual(matricula_id) {
    const response = await Axios()
      .get(`relatorios/turmaBoletimIndividual/${matricula_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async alunosPorUnidadeEScolar(franquia_id) {
    const response = await Axios()
      .get(`relatorios/alunos_por_unidade/${franquia_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async boletinsFundamentalMenorFranquia(franquia_id) {
    const response = await Axios()
      .get(`relatorios/boletins-fundamental-menor-franquia/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async boletinsFundamentalMaiorFranquia(franquia_id) {
    const response = await Axios()
      .get(`relatorios/boletins-fundamental-maior-franquia/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async boletinsEnsinoMedioFranquia(franquia_id) {
    const response = await Axios()
      .get(`relatorios/boletins-ensino-medio-franquia/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatoriosBnccFranquia(franquia_id) {
    const response = await Axios()
      .get(`relatorios/relatorios-bncc-franquia/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async escolasTurmasAlunos() {
    const response = await Axios()
      .get("relatorios/escolas_turmas_alunos", { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async listarTodasTurmas() {
    const response = await Axios()
      .get("relatorios/listar-todas-turmas", { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async listarTurmasFranquia(franquia_id) {
    const response = await Axios()
      .get(`relatorios/listar-turmas-franquia/${franquia_id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async inscritosCongresso() {
    const response = await Axios()
      .get("relatorios/inscritos-congresso", { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async diarioFrequencia(gestao_id, mes) {
    const { id } = mes;
    const response = await Axios()
      .get(`relatorios/diario-frequencia/${gestao_id}/${id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async diarioAulas(gestao_id, mes) {
    const { id } = mes;
    const response = await Axios()
      .get(`relatorios/diario-aulas/${gestao_id}/${id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async diarioNotas(gestao_id) {
    const response = await Axios()
      .get(`relatorios/diario-notas/${gestao_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async insercaoAulas() {
    const response = await Axios()
      .get("relatorios/insercao-aulas", {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async insercaoAulasXls() {
    const response = await Axios()
      .get("relatorios/insercao-aulas-xls", {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/xls" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "insercao_aulas.xls";
        link.click();
      });
    return response;
  }

  async lotacao() {
    const response = await Axios()
      .get("relatorios/relatorio-lotacao", {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async planoBimestral(gestao_id, bimestre) {
    const response = await Axios()
      .get(`relatorios/plano-bimestral/${gestao_id}/${bimestre}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async planoBimestralInfantil(gestao_id, bimestre) {
    const response = await Axios()
      .get(`relatorios/plano-bimestral-infantil/${gestao_id}/${bimestre}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async planoMensalInfantil(gestao_id, mes) {
    const response = await Axios()
      .get(`relatorios/plano-mensal-infantil/${gestao_id}/${mes}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async planoMensal(gestao_id, disciplina_id, mes) {
    const response = await Axios()
      .get(`relatorios/plano-mensal/${gestao_id}/${disciplina_id}/${mes}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async resultadoPorDisciplina(turma) {
    const response = await Axios()
      .get(`relatorios/resultado-por-disciplinas/${turma}`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async capaDiarioClasse(gestao_id) {
    const response = await Axios()
      .get(`relatorios/capa/diario-classes/${gestao_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async resumoMovimentoEscolar(franquia_id) {
    const response = await Axios()
      .get(`relatorios/resumo-movimento-escolar/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async declaracaoConclusao(matricula_id) {
    const response = await Axios()
      .get(`relatorios/declaracao-conclusao/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async certificadoConclusao(matricula_id) {
    const response = await Axios()
      .get(`relatorios/certificado-conclusao/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async declaracaoCurso(matricula_id) {
    const response = await Axios()
      .get(`relatorios/declaracao-curso/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async fichaDeMatricula(matricula_id) {
    const response = await Axios()
      .get(`relatorios/ficha-matricula/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async contratoPrestacoes(matricula_id) {
    const response = await Axios()
      .get(`relatorios/contrato-prestacoes/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async matriculasPorEscola() {
    const response = await Axios()
      .get(`relatorios/matriculas-por-escola`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async matriculasPorSerie() {
    const response = await Axios()
      .get(`relatorios/matriculas-serie`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatoriosEvasao(franquia_id) {
    const response = await Axios()
      .get(`relatorios/evasao/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatorioAulas(franquia_id) {
    const response = await Axios()
      .get(`relatorios/aulas/franquia/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatorioAulasGeral() {
    const response = await Axios()
      .get(`relatorios/aulas/franquia-geral`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async fichaDeAlergia(turma_id) {
    const response = await Axios()
      .get(`relatorios/turmas/ficha-de-alergia/${turma_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async fichaDeAlergiaTodasTurmas(franquia_id) {
    const response = await Axios()
      .get(`relatorios/turmas/ficha-de-alergia-todas-turmas/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async documentoJustificativaFalta(aula_id, matricula_id) {
    const response = await Axios()
      .get(`relatorios/falta/baixar-documento/${aula_id}/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `documento_de_justificativa_${matricula_id}.pdf`;
        link.click();
      });
    return response;
  }

  async frenquenciaIndividual(matricula_id, mes) {
    const response = await Axios()
      .get(`relatorios/frenquencia-individual/${matricula_id}/${mes}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `frequencia_individual_${matricula_id}.pdf`;
        link.click();
      });
    return response;
  }

  async relatorioDeListagemDosPlanosLancados(franquia_id) {
    const response = await Axios()
      .get(`relatorios/historico-lancamentos-planos/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async relatorioDeListagemDosPlanosLancadosGeral() {
    const response = await Axios()
      .get(`relatorios/historico-lancamentos-planos-geral`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async alunosNaoMatriculados(franquia_id) {
    const response = await Axios()
      .get(`relatorios/alunos-nao-matriculados/${franquia_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async alunosNaoMatriculadosGeral() {
    const response = await Axios()
      .get(`relatorios/alunos-nao-matriculados-geral`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async qtdProfessoresPorDisciplina() {
    const response = await Axios()
      .get(`relatorios/professores-por-disciplina`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      });
    return response;
  }

  async carteira(matricula) {
    const response = await Axios()
      .get(`cartao/${matricula}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `carteira_${matricula}.pdf`;
        link.click();
      });
    return response;
  }

  async exibirCarteira(matricula) {
    const base64 = await Axios()
      .get(`cartao/${matricula}`, { responseType: "arraybuffer" })
      .then((response) => Buffer.from(response.data, "binary").toString("base64"));
    return `data:application/pdf;base64, ${base64}`;
  }

  async exibirRecibo(mensalidade) {
    const base64 = await Axios()
      .get(`relatorios/${mensalidade}/recibo`, { responseType: "arraybuffer" })
      .then((response) => Buffer.from(response.data, "binary").toString("base64"));
    return `data:application/pdf;base64, ${base64}`;
  }

  async exibirReciboDaParcela(parcelaId) {
    const base64 = await Axios()
      .get(`relatorios/${parcelaId}/reciboParcela`, { responseType: "arraybuffer" })
      .then((response) => Buffer.from(response.data, "binary").toString("base64"));
    return `data:application/pdf;base64, ${base64}`;
  }

  async financeiroPorEscola(franquia_id, mes) {
    const response = await Axios()
      .get(`/relatorios/situacao-financeira/${franquia_id}/franquia/${mes}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `situacao_financeira_escola_${franquia_id}.pdf`;
        link.click();
      });
    return response;
  }

  async financeiroPorTurma(turma_id, mes) {
    const response = await Axios()
      .get(`/relatorios/situacao-financeira/${turma_id}/turma/${mes}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `situacao_financeira_turma_${turma_id}.pdf`;
        link.click();
      });
    return response;
  }

  async planoSemanal(gestao_id, mes_id, disciplina_id) {
    const response = await Axios()
      .get(`relatorios/plano-semanal/${gestao_id}/${mes_id}/${disciplina_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `plano_semanal_${gestao_id}.pdf`;
        link.click();
      });
    return response;
  }
}
export default new RelatoriosService();
