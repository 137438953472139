import CalendarioLetivoIndexPage from "@/Pages/CalendarioLetivo/index.vue";
import NewEditCalendarioLetivoPage from "@/Pages/CalendarioLetivo/NewEditCalendarioLetivoPage.vue";
import ShowCalendarioLetivoPage from "@/Pages/CalendarioLetivo/ShowCalendarioLetivoPage.vue";
import CalendarioLetivoPage from "@/Pages/CalendarioLetivo/CalendarioLetivoPage.vue";

export default [
  {
    path: "/calendario-letivo/create",
    name: "calendario-letivo.create",
    component: NewEditCalendarioLetivoPage,
    props: { editing: false },
  },
  {
    path: "/calendario-letivo/edit/:circuito_id",
    name: "calendario-letivo.edit",
    component: NewEditCalendarioLetivoPage,
    props: { editing: true },
  },
  {
    path: "/calendario-letivo/etapas/:circuito_id",
    name: "calendario-letivo.etapas",
    component: CalendarioLetivoPage,
    props: { editing: true },
  },
  {
    path: "/calendario-letivo/show/:circuito_id",
    name: "calendario-letivo.show",
    component: ShowCalendarioLetivoPage,
    props: { editing: true },
  },
];
