<template>
  <main-template>
    <v-container>
      <v-row>
        <v-col>
          <h1>
            <v-btn
              color="primary"
              x-small
              text
              @click="() => $router.push({ name: 'calendario-letivo' })"
            >
              <v-icon left> fa fa-angle-left </v-icon>
            </v-btn>
            Painel Administrativo - Calendário Letivo {{ anoAtual }}
          </h1>
        </v-col>
      </v-row>

      <v-container class="mt-5">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-title class="blue text-center white--text">Legendas</v-card-title>
              <v-card-text>
                <v-row>
                  <!-- Exibir as legendas em colunas dentro de um v-row -->
                  <v-col v-for="item in eventosList" :key="item.texto" cols="6" md="4">
                    <v-chip :color="item.cor" class="ma-2" />
                    <span>{{ item.text }}</span>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <!-- Exibir os eventos em colunas dentro de um v-row -->
              <v-card-title>Eventos do Ano {{ anoAtual }}</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-data-table
                  v-if="eventos.length"
                  :headers="headers"
                  :items="eventosOrdenados"
                  item-key="start"
                >
                  <!-- Formatação da data -->
                  <template v-slot:item.start="{ item }">
                    <span>{{ formatarData(item.start) }}</span>
                  </template>

                  <!-- Exibição da cor antes do name -->
                  <template v-slot:item.name="{ item }">
                    <div class="d-flex align-center">
                      <div
                        :style="{
                          backgroundColor: item.color,
                          width: '15px',
                          height: '15px',
                          borderRadius: '50%',
                          marginRight: '8px',
                        }"
                      ></div>
                      <span>{{ item.name }}</span>
                    </div>
                  </template>

                  <!-- Exibição da cor antes da descrição -->
                  <template v-slot:item.descricao="{ item }">
                    <div class="d-flex align-center">
                      <span>{{ item.descricao }}</span>
                    </div>
                  </template>

                  <!-- Ação para editar e remover o evento -->
                  <template v-slot:item.action="{ item }">
                    <v-btn icon small color="blue" @click="abrirModalEdicao(item)">
                      <v-icon>fa fa-edit</v-icon>
                    </v-btn>
                    <v-btn icon small color="red" @click="removerEvento(item)">
                      <v-icon>fa fa-trash</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>

                <p v-else class="text-center">Nenhum evento cadastrado para este ano.</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <!-- Calendário -->
      <v-container class="mt-5">
        <v-row>
          <v-col v-for="(mes, index) in meses" :key="index" cols="12" md="6" lg="4">
            <v-card>
              <v-card-title>{{ mes }}</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-calendar
                  ref="calendar"
                  :key="mes"
                  type="month"
                  :start="getFirstDayOfMonth(index)"
                  :events="getEventosDoMes(anoAtual, index)"
                  :show-adjacent-months="false"
                  class="calendario-fixo"
                  @click:date="abrirModalEvento"
                  event-color="color"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <!-- Modal para Adicionar Evento -->
      <e-modal-add-evento
        :modal-aberto="modalAberto && !editando"
        :novo-evento="novoEvento"
        :eventos-list="eventosList"
        :loading="loading"
        @add-evento="addEvento"
        @fechar-modal="modalAberto = false"
      ></e-modal-add-evento>

      <!-- Modal para Editar Evento -->
      <e-modal-edit-evento
        :modal-aberto="modalAberto && editando"
        :novo-evento="novoEvento"
        :eventos-list="eventosList"
        :loading="loading"
        @editar-evento="editarEvento"
        @fechar-modal="modalAberto = false"
      ></e-modal-edit-evento>

      <!-- Modal de Confirmação -->
      <e-modal-confirmar-evento
        :modal-confirmacao-aberto="modalConfirmacaoAberto"
        :evento-selecionado="eventoSelecionado"
        :loading="loading"
        @confirmar-remocao="confirmarRemocao"
        @fechar-modal="modalConfirmacaoAberto = false"
      ></e-modal-confirmar-evento>
    </v-container>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import EModalAddEvento from "@/components/Calendario/EModalAddEvento.vue";
import EModalEditEvento from "@/components/Calendario/EModalEditEvento.vue";
import EModalConfirmarEvento from "@/components/Calendario/EModalConfirmarEvento.vue";

export default {
  components: {
    EModalAddEvento,
    EModalEditEvento,
    EModalConfirmarEvento,
  },
  data() {
    return {
      headers: [
        { text: "Categoria", align: "start", value: "name" },
        { text: "Evento", align: "start", value: "descricao" },
        { text: "Data", align: "start", value: "start" },
        { text: "Ação", align: "center", value: "action" },
      ],
      meses: [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ],
      eventosList: [],
      novoEvento: { data: null, evento_id: null, descricao: null },
      modalAberto: false,
      eventos: [],
      modalConfirmacaoAberto: false,
      eventoSelecionado: null,
      loading: false,
      editando: false,
    };
  },
  mounted() {
    this.loadEventos();
    this.loadEventosCalendario();
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
    anoAtual() {
      return this.user?.ano.descricao || new Date().getFullYear();
    },
    eventosOrdenados() {
      return this.eventos.slice().sort((a, b) => new Date(a.start) - new Date(b.start));
    },
    isFormValid() {
      return this.novoEvento.tipo && this.novoEvento.descricao;
    },
  },
  watch: {
    anoAtual(novoAno) {
      this.carregarEventos(novoAno);
    },
  },
  methods: {
    formatarData(data) {
      const date = new Date(data);
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      return date.toLocaleDateString("pt-BR", { day: "2-digit", month: "long" });
    },

    abrirModalConfirmacao(evento) {
      this.eventoSelecionado = { ...evento, id: evento.id };
      this.modalConfirmacaoAberto = true;
    },

    async confirmarRemocao() {
      this.loading = true;
      try {
        await this.$services.calendarioLetivoService.removerEvento(this.eventoSelecionado.id);
        this.eventos = this.eventos.filter((e) => e.id !== this.eventoSelecionado.id);
        this.$toast.success("Evento removido com sucesso!");
      } catch (error) {
        this.$toast.error("Erro ao remover o evento.");
        this.$handleError(error);
      } finally {
        this.loading = false;
        this.modalConfirmacaoAberto = false;
        this.eventoSelecionado = null;
      }
    },

    async removerEvento(evento) {
      this.abrirModalConfirmacao(evento);
    },
    getFirstDayOfMonth(index) {
      return new Date(this.anoAtual, index, 1).toISOString().substr(0, 10);
    },

    abrirModalEvento(day) {
      const dataSelecionada = new Date(day.date);
      this.novoEvento.data = day.date;
      this.modalAberto = true;
      this.editando = false; // Não está editando
    },

    async abrirModalEdicao(evento) {
      await this.loadEventos(); // Carregar eventos antes de abrir o modal de edição

      this.novoEvento = {
        id: evento.id,
        data: evento.start,
        evento_id: evento.evento_id,
        descricao: evento.descricao,
      };
      this.modalAberto = true;
      this.editando = true; // Está editando
    },

    async addEvento() {
      const eventoSelecionado = this.eventosList.find((e) => e.id === this.novoEvento.evento_id);
      if (!eventoSelecionado) {
        this.$toast.error("Erro ao buscar os detalhes do evento.");
        return;
      }

      const eventoParaSalvar = {
        evento_id: eventoSelecionado.id,
        name: eventoSelecionado.text,
        descricao: this.novoEvento.descricao,
        start: this.novoEvento.data,
        end: this.novoEvento.data,
        color: eventoSelecionado.cor,
        ano_id: this.user?.ano.id,
      };

      this.loading = true;
      try {
        const response = await this.$services.calendarioLetivoService.salvarCalendario(
          eventoParaSalvar
        );

        this.eventos.push({
          id: response.id,
          start: response.start,
          end: response.end,
          name: response.name,
          evento_id: response.evento_id,
          descricao: response.descricao,
          color: response.color,
        });

        this.$toast.success("Evento salvo com sucesso!");
      } catch (error) {
        this.$toast.error("Erro ao salvar o evento.");
        this.$handleError(error);
      } finally {
        this.loading = false;
        this.modalAberto = false;
        this.novoEvento = { data: null, evento_id: null, descricao: null };
      }
    },

    async editarEvento() {
      const eventoSelecionado = this.eventosList.find((e) => e.id === this.novoEvento.evento_id);

      if (!eventoSelecionado) {
        this.$toast.error("Erro ao buscar os detalhes do evento.");
        return;
      }

      const eventoParaSalvar = {
        id: this.novoEvento.id,
        evento_id: eventoSelecionado.id,
        name: eventoSelecionado.text,
        descricao: this.novoEvento.descricao,
        start: this.novoEvento.data,
        end: this.novoEvento.data,
        color: eventoSelecionado.cor,
        ano_id: this.user?.ano.id,
      };
      this.loading = true;
      try {
        await this.$services.calendarioLetivoService.atualizarEvento(eventoParaSalvar);
        const index = this.eventos.findIndex((e) => e.id === eventoParaSalvar.id);
        if (index !== -1) {
          this.eventos.splice(index, 1, eventoParaSalvar);
        }

        this.$toast.success("Evento editado com sucesso!");
      } catch (error) {
        this.$toast.error("Erro ao editar o evento.");
        this.$handleError(error);
      } finally {
        this.loading = false;
        this.modalAberto = false;
        this.novoEvento = { data: null, evento_id: null, descricao: null };
      }
    },

    getEventosDoMes(ano, index) {
      const mes = index;

      const eventosFiltrados = this.eventos.filter((evento) => {
        const eventoData = new Date(evento.start);
        const eventoMes = eventoData.getUTCMonth();
        return eventoMes === mes;
      });

      return eventosFiltrados.map((evento) => ({
        ...evento,
        color: evento.color || "#1976D2",
      }));
    },
    async loadEventosCalendario() {
      try {
        const response = await this.$services.calendarioLetivoService.syncAll();

        this.eventos = response.map((evento) => ({
          name: evento.evento?.legenda,
          descricao: evento.descricao,
          id: evento.id,
          evento_id: evento.evento_id,
          start: evento.start,
          end: evento.end,
          color: evento.color,
        }));
      } catch (error) {
        this.$handleError(error);
      }
    },

    async loadEventos() {
      try {
        const data = await this.$services.eventoCalendarioLetivoService.syncAll();

        this.eventosList = data.map((evento) => ({
          id: evento.id ?? null,
          text: evento.legenda ?? "Evento sem nome",
          value: evento.id ?? null,
          cor: evento.cor ?? null,
        }));
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
.v-sheet {
  border: 1px solid #ddd;
  border-radius: 8px;
}
.calendario-fixo {
  min-height: 400px;
  max-height: 400px;
  overflow: hidden;
}
</style>
