<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'gestoesDeAulas',
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Plano Semanal
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green lighten-1" outlined>
          <v-col cols="10">
            <v-card-text>
              <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
              <h4>
                Disciplina:
                {{
                  !gestaoDeAula.is_polivalencia == 1
                    ? gestaoDeAula.disciplina.descricao
                    : gestaoDeAula.disciplina
                }}
              </h4>
              <h4>
                Professor:
                {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[carregando...]" }}
              </h4>
            </v-card-text>
          </v-col>

          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  x-small
                  class="ml-1 white--text"
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
                  @click="() => verHistoricoPlanos()"
                >
                  <span>Histórico do lancamento de planos</span>
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-card>
      </v-col>

      <v-col cols="12 text-center" class="pt-2">
        <template>
          <v-toolbar>
            <v-tabs dark v-model="form.mes_id" background-color="primary" grow>
              <v-tab
                v-for="item of $constants.mesesPlanoSemanal"
                :key="item.id"
                @click="() => loadData(item.id)"
              >
                <v-badge v-if="form.mes_id === item.id" color="success">
                  {{ item.descricao }}
                </v-badge>
                <div v-else>{{ item.descricao }}</div>
              </v-tab>
            </v-tabs>
          </v-toolbar>
        </template>
      </v-col>
    </v-row>
    <v-tabs-items v-model="form.mes_id">
      <v-tab-item v-for="item in $constants.mesesPlanoSemanal" :key="item.id">
        <v-card flat>
          <v-card-text>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form
                @submit.prevent="handleSubmit(createOrUpdate)"
                v-if="loadPlano"
                :disabled="submittingForm"
              >
                <v-row>
                  <v-col cols="6" md="6">
                    <e-label>Escolha a Semana</e-label>
                    <e-autocomplete
                      :items="$constants.semanas"
                      :return-object="false"
                      item-text="descricao"
                      item-value="id"
                      v-model="form.semana_id"
                      label="Escolha a Semana"
                      dense
                      solo
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <ValidationProvider name="Conteúdo" rules="required" v-slot="{ errors }">
                      <e-label>Conteúdo</e-label>
                      <v-textarea
                        solo
                        dense
                        counter="300"
                        maxlength="300"
                        v-model="form.conteudo"
                        :error-messages="errors"
                        @input="form.conteudo = form.conteudo.slice(0, 300)"
                      />
                    </ValidationProvider>

                    <ValidationProvider name="Habilidades" rules="required" v-slot="{ errors }">
                      <e-label>Habilidades</e-label>
                      <v-textarea
                        solo
                        dense
                        counter="300"
                        maxlength="300"
                        v-model="form.habilidades"
                        :error-messages="errors"
                        @input="form.habilidades = form.habilidades.slice(0, 300)"
                      />
                    </ValidationProvider>

                    <ValidationProvider name="Objetivo" rules="required" v-slot="{ errors }">
                      <e-label>Objetivo</e-label>
                      <v-textarea
                        solo
                        dense
                        counter="300"
                        maxlength="300"
                        v-model="form.objetivo"
                        :error-messages="errors"
                        @input="form.objetivo = form.objetivo.slice(0, 300)"
                      />
                    </ValidationProvider>

                    <ValidationProvider name="Metodologia" rules="required" v-slot="{ errors }">
                      <e-label>Metodologia</e-label>
                      <v-textarea
                        solo
                        dense
                        counter="300"
                        maxlength="300"
                        v-model="form.metodologia"
                        :error-messages="errors"
                        @input="form.metodologia = form.metodologia.slice(0, 300)"
                      />
                    </ValidationProvider>

                    <ValidationProvider
                      name="Atividade de Verificação"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <e-label>Atividade de Verificação</e-label>
                      <v-textarea
                        solo
                        dense
                        counter="300"
                        maxlength="300"
                        v-model="form.atividade_verificacao"
                        :error-messages="errors"
                        @input="
                          form.atividade_verificacao = form.atividade_verificacao.slice(0, 300)
                        "
                      />
                    </ValidationProvider>

                    <ValidationProvider name="Recursos" rules="required" v-slot="{ errors }">
                      <e-label>Recursos</e-label>
                      <v-textarea
                        solo
                        dense
                        counter="300"
                        maxlength="300"
                        v-model="form.recursos"
                        :error-messages="errors"
                        @input="form.recursos = form.recursos.slice(0, 300)"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>

                <v-col cols="12">
                  <v-row>
                    <v-btn
                      color="primary"
                      :disabled="submittingForm"
                      :loading="submittingForm"
                      type="submit"
                    >
                      Enviar Dados
                    </v-btn>
                  </v-row>
                </v-col>
              </form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  mounted() {
    this.loadData();
  },
  data() {
    return {
      habilidade: null,
      experiencia_: null,
      modalDeletarObjetivo: false,
      mensagem: "",
      carregandoHabilidades: false,
      showMessage: false,
      dialog: false,
      search: "",
      submittingForm: false,
      form: {
        mes_id: 0,
        semana_id: null,
        path: "",
        file: null,
      },
      openDrawer: false,
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      loadPlano: true,
      semanaSelecionada: null,
    };
  },
  watch: {
    "form.semana_id": function (newVal, oldVal) {
      if (newVal !== oldVal && newVal !== null) {
        this.loadData();
      }
    },
    "form.mes_id": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadData(newVal);
      }
    },
  },
  methods: {
    async verHistoricoPlanos() {
      this.openDrawer = !this.openDrawer;
    },
    async createOrUpdate() {
      if (
        this.form.semana_id === null ||
        this.form.semana_id === undefined ||
        this.form.semana_id === ""
      ) {
        this.$toast.danger("Por favor, selecione uma semana.");
        return;
      }

      this.$loaderService.open("Carregando dados...");
      try {
        const { gestaoDeAulaId } = this.$route.params;
        const { mes_id, semana_id } = this.form;

        this.form.mes_id = mes_id;
        this.form.semana_id = semana_id;
        this.form.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        this.form.disciplina_id = this.disciplinaId;

        await this.$services.planosSemanaisService.createOrUpdate(this.form);
        this.$toast.success("Plano semanal salvo com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async loadData(id) {
      this.submittingForm = false;
      const { gestaoDeAulaId } = this.$route.params;
      this.disciplinaId = parseInt(this.$route.params.disciplinaId, 10);

      if (id) this.form.mes_id = id;

      try {
        this.$loaderService.open("Carregando dados...");
        this.gestaoDeAula = await this.$services.gestoesDeAulasService.visualizar(gestaoDeAulaId);

        const response = await this.$services.planosSemanaisService.planoSemanal(
          gestaoDeAulaId,
          this.form.mes_id,
          this.form.semana_id,
          this.disciplinaId
        );

        if (this.gestaoDeAula.disciplinas.length > 0) {
          const disciplina = this.gestaoDeAula.disciplinas.filter((i) => {
            return i.id === this.disciplinaId;
          });

          this.gestaoDeAula.disciplina = disciplina[0].descricao;
        }

        if (response && response.plano) {
          this.form = { ...response.plano };
        } else {
          this.form = {
            mes_id: this.form.mes_id,
            semana_id: this.form.semana_id,
            conteudo: "",
            habilidades: "",
            objetivo: "",
            metodologia: "",
            atividade_verificacao: "",
            recursos: "",
          };
        }

        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async onFileSelected(file) {
      if (!file) return;

      this.isUploading = true;

      const { gestaoDeAulaId } = this.$route.params;
      const { mes_id, semana_id } = this.form;
      if (
        this.form.semana_id === null ||
        this.form.semana_id === undefined ||
        this.form.semana_id === ""
      ) {
        this.$toast.danger("Por favor, selecione uma semana.");
        return;
      }
      try {
        const response = await this.$services.planosSemanaisService.planoSemanalPDF(
          gestaoDeAulaId,
          mes_id,
          semana_id,
          file
        );

        this.$set(this.form, "path", response.path);
      } catch (error) {
        this.$toast.danger(error.error || "Erro ao enviar o arquivo.");
        this.$handleError(error);
      } finally {
        this.isUploading = false;
      }
    },

    async downloadPlanoSemanal(semestre) {
      const { gestaoDeAulaId } = this.$route.params;
      const { mes_id, semana_id } = this.form;

      try {
        await this.$services.planosSemanaisService.downloadPlanoSemanal(
          gestaoDeAulaId,
          mes_id,
          semana_id
        );
      } catch (error) {}
    },
  },
};
</script>

<style></style>
