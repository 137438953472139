<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title class="primary">
          <span style="color: #fff" class="text-h5">Exportar Aula</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(enableSubmit)" :disabled="false">
                <v-row>
                  <v-col class="pt-4 pb-0" cols="12">
                    <e-label class="font-weight-medium">Turma</e-label>
                    <p class="font-weight-regular">
                      <v-select
                        :items="turmas"
                        item-text="turma.descricao"
                        item-value="turma.id"
                        :hint="`${turmaSelecionada.turma.franquia.descricao}`"
                        return-object
                        label="Selecione o campo abaixo"
                        v-model="turmaSelecionada"
                      ></v-select>
                    </p>
                  </v-col>
                  <v-col class="pt-4 pb-0" cols="12">
                    <e-label class="font-weight-medium">Tipo de Aula</e-label>
                    <p class="font-weight-regular">
                      {{ gestaoDeAula.tipo_de_aula }}
                    </p>
                  </v-col>
                  <v-col class="pt-0 pb-0" :cols="12" :sm="4">
                    <v-dialog ref="dialog" v-model="modal" :return-value.sync="date" width="290px">
                      <template v-slot:activator="{ on, attrs }">
                        <e-label>Selecione uma data</e-label>
                        <v-text-field
                          dense
                          solo
                          v-model="data"
                          label="Data"
                          v-bind="attrs"
                          v-on="on"
                          readonly
                        ></v-text-field>
                      </template>
                      <v-date-picker color="primary" v-model="dataAulaDuplicada" scrollable>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="modal = false"> Cancel </v-btn>
                        <v-btn text color="primary" @click="$refs.dialog.save(date)"> OK </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    v-if="aulaDuplicada?.tipo_de_aula !== 'Aula Remota'"
                    cols="6"
                  >
                    <ValidationProvider name="Horário da aula" rules="required" v-slot="{ errors }">
                      <e-label>Selecione um horário</e-label>
                      <e-autocomplete
                        :items="horarios"
                        :error-messages="errors"
                        label="Selecione uma opção"
                        :item-text="(item) => item.descricao"
                        v-model="horarioSelecionado"
                        return-object
                        dense
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <e-label class="font-weight-medium">Conteúdos / Atividades Realizadas</e-label>
                    <p class="font-weight-regular">
                      {{ gestaoDeAula.conteudos_atividades }}
                    </p>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    style="color: #fff"
                    class="red darken-1"
                    @click="$emit('dialogChange', false)"
                  >
                    Fechar
                  </v-btn>
                  <v-btn style="color: #fff" class="green darken-1" type="submit"> Salvar </v-btn>
                </v-card-actions>
              </form>
            </ValidationObserver>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <e-modal-confirm
      :confirmDialog="confirmDialog"
      :objectModal="objectModal"
      @changeModalConfirm="enableSubmit"
      @changeActionResponse="exportarAula"
    >
    </e-modal-confirm>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    horarios: {
      type: Array,
      require: true,
    },
    submittingForm: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      modal: false,
      gestaoDeAula: {},
      confirmDialog: false,
      aulaDuplicada: {},
      dataAulaDuplicada: null,
      turmas: [],
      turmaSelecionada: {
        turma: {
          id: null,
          franquia: {
            descricao: "",
            fantasia: "",
          },
        },
      },
      horarioSelecionado: {
        descricao: "Escolha um horario",
      },
      objectModal: {
        title: "Exportar aula",
        message: "Tem certeza que deseja exportar esta aula?",
      },
    };
  },
  watch: {
    form: {
      immediate: true,
      handler(val) {
        this.gestaoDeAula = val;
      },
    },
    dataAulaDuplicada(value) {
      const date = new Date(value);
      const data_old = new Intl.DateTimeFormat("pt-BR", { timeZone: "UTC" }).format(date);
      this.data = data_old.replaceAll("/", "/");
    },
    dialog: {
      async handler(val) {
        if (val) {
          this.$loaderService.open("Carregando gestões de aula");
          try {
            const gestoes = await this.$services.gestoesDeAulasService.listarGestoesProfessor(
              this.gestaoDeAula
            );
            this.turmas = gestoes;
          } catch (error) {
            this.$handleError(error);
          } finally {
            this.$loaderService.close();
          }
        }
      },
    },
  },
  methods: {
    enableSubmit() {
      this.confirmDialog = !this.confirmDialog;
      this.gestaoDeAula.data = this.dataAulaDuplicada;
    },
    async exportarAula() {
      this.$loaderService.open("Exportando a Aula");
      try {
        this.gestaoDeAula.horario_inicial = this.horarioSelecionado
          ? this.horarioSelecionado.inicio
          : null;
        this.gestaoDeAula.horario_final = this.horarioSelecionado
          ? this.horarioSelecionado.final
          : null;
        this.gestaoDeAula.horario_id = this.horarioSelecionado ? this.horarioSelecionado.id : null;
        this.gestaoDeAula.instrutorDisciplinaTurma_id = parseInt(this.turmaSelecionada.id, 10);
        this.gestaoDeAula.turma_id = this.turmaSelecionada.turma.id;
        this.confirmDialog = !this.confirmDialog;

        this.$emit("dialogChange", false);
        await this.$services.aulasService.criar(this.gestaoDeAula);

        this.$toast.success("Aula exportada com sucesso!");
        this.$router.push({ name: "aulas" });
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
  },
};
</script>
