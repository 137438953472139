<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Categoria </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <div class="d-flex mb-2 justify-space-between pa-0">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" @click="criarEventos" dark v-bind="attrs" v-on="on">
                Criar Categoria
              </v-btn>
            </template>
          </v-menu>
          <v-text-field
            v-model="search"
            class="ml-2 search-input"
            append-icon="mdi-magnify"
            label="Pesquisar..."
            solo
            autofocus
            dense
            single-line
            hide-details
          />
        </div>
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="eventosList"
          :items-per-page="10"
          :custom-filter="dataTableFilter"
          :search="search"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="evento.estaDeletado ? ['deleted'] : []"
                v-for="evento of items"
                :key="evento.id"
              >
                <td>{{ evento.id }}</td>
                <td>{{ evento.ano ? evento.ano.descricao : "- - -" }}</td>
                <td>{{ evento.legenda }}</td>
                <!-- <td>{{ evento.contexto }}</td> -->
                <td>
                  <v-tooltip v-if="!evento.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => editarEvento(evento)"
                      >
                        <v-icon small>fa fa-edit </v-icon>
                      </v-btn>
                    </template>
                    <span>Editar evento</span>
                  </v-tooltip>

                  <v-tooltip v-if="!evento.estaDeletado" color="error" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => deletarEvento(evento)"
                      >
                        <v-icon small>fa fa-trash </v-icon>
                      </v-btn>
                    </template>
                    <span>Apagar evento</span>
                  </v-tooltip>
                  <v-tooltip v-if="evento.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarEvento(evento)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar evento</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";

export default {
  data() {
    return {
      eventosList: [],
      eventosListGeral: [],
      evento_id: null,
      submittingForm: false,
      anoId: null,
      dialog: false,
      dataTableFilter,
      search: "",
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Ano", value: "ano" },
          { text: "Legenda", value: "legenda" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
      anos: [],
    };
  },
  mounted() {
    this.loadEventos();
    this.getAnos();
  },
  watch: {
    carregandoEventos(value) {
      if (value && this.eventosList.length === 0) {
        this.$loaderService.open("Carregando Eventos");
      } else {
        this.$loaderService.close();
      }
    },
  },
  methods: {
    async loadEventos() {
      try {
        const data = await this.$services.eventoCalendarioLetivoService.syncAll();
        this.eventosList = data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    criarEventos() {
      this.$router.push({ name: "eventos-calendario-letivo.create" });
    },
    editarEvento(evento) {
      this.$router.push({
        name: "eventos-calendario-letivo.edit",
        params: { evento_id: evento.id },
      });
    },
    showEvento(evento) {
      this.$router.push({ name: "calendario-letivo.show", params: { evento_id: evento.id } });
    },
    async deletarEvento(evento) {
      this.$modals.danger({
        title: "Cuidado!",
        message: "Tem certeza que deseja apagar esta evento?",
        confirmationCode: evento.id,
        confirmationMessage: `Por favor, digite <strong>${evento.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Deletando o Evento");
              try {
                await this.$services.eventoCalendarioLetivoService.deletarEvento(evento);
                this.$toast.success("Evento deletado com sucesso");
                this.loadEventos();
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    recuperarEvento(evento) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta evento?",
        confirmationCode: evento.id,
        confirmationMessage: `Por favor, digite <strong>${evento.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando o Evento");
              try {
                await this.$services.eventoCalendarioLetivoService.recuperarEvento(evento);
                this.$toast.success("Evento recuperado com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
    async getAnos() {
      try {
        const data = await this.$services.anosService.syncAll();
        this.anos = data;
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
