<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Selecione um Plano Semanal </v-card-title>

        <v-select
          :items="disciplinas"
          item-text="descricao"
          item-value="id"
          v-model="disciplinaSelecionada"
          return-object
          label="Selecione uma disciplina"
          solo
        ></v-select>

        <v-select
          :items="$constants.mesesPlanoSemanal"
          item-text="descricao"
          item-value="id"
          v-model="mes"
          return-object
          label="Selecione um mês"
          solo
        ></v-select>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :dialog="dialog"
            @status="dialog = $event"
            color="blue darken-1"
            text
            @click="cancelar()"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="gerarPlanoBimestral(gestaoDeAulasId, mes?.id)"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    gestaoDeAulasId: {
      type: Number,
    },
    disciplinas: {
      type: Array,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    typeModel: {
      type: String,
    },
  },

  watch: {
    dialog(val) {
      this.$emit("status", val);
    },
    typeModel(val) {
      this.type = val;
    },
  },
  data() {
    return {
      disciplinasArray: this.disciplinas,
      disciplinaSelecionada: null,
      disciplinasDaGestao: [],
      type: null,
      bimestre: null,
    };
  },
  methods: {
    async gerarPlanoBimestral(gestaoDeAulasId, bimestre) {
      try {
        this.$loaderService.open("Gerando plano de aula...");
        const disciplinaId = this.disciplinaSelecionada.id;
        await this.$services.relatoriosService.planoBimestral(
          parseInt(gestaoDeAulasId, 10),
          parseInt(bimestre, 10),
          parseInt(disciplinaId, 10)
        );
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },
    cancelar() {
      this.disciplinaSelecionada = null;
      this.bimestre = null;
      this.$emit("status", false);
    },
  },
};
</script>
