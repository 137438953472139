import Axios from "@/plugins/Axios";
import store from "@/store";
import EventoCalendarioLetivo from "@/Models/EventoCalendarioLetivo";

export class CalendarioLetivoService {
  async syncAll() {
    const currentUser = store.getters["Auth/user"];
    const response = await Axios().get(`calendario-letivo?ano_id=${currentUser.ano_id}`);
    const eventos = response.data.map((evento) => new EventoCalendarioLetivo(evento));
    return eventos;
  }

  async salvarCalendario(evento) {
    const response = await Axios().post("calendario-letivo", evento);
    const novoEvento = response.data;
    return novoEvento;
  }

  async atualizarEvento(evento) {

    const response = await Axios().put(`calendario-letivo/${evento.id}`, evento);
    const eventoList = response.data;
    return eventoList;
  }

  async deletarCircuito(circuito) {
    const response = await Axios().delete(`calendario-letivo/${circuito.id}`);
    const circuitoDeletada = response.data;
    return circuitoDeletada;
  }

  async getCircuito(circuitoId) {
    const response = await Axios().get(`calendario-letivo/${circuitoId}`);
    const result = response.data;
    return result;
  }

  async removerEvento(eventoId) {
    const response = await Axios().delete(`calendario-letivo/${eventoId}`);
    const eventoRemovido = response.data;
    return eventoRemovido;
  }
}
export default new CalendarioLetivoService();
