<template>
  <v-dialog v-model="modalAberto" max-width="800px">
    <v-card>
      <v-card-title> Adicionar Evento para o Dia {{ formatarData(novoEvento.data) }} </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="emitirEvento">
          <v-select
            v-model="novoEvento.evento_id"
            label="Tipo de Evento"
            :items="eventosList"
            item-text="text"
            item-value="value"
            required
          ></v-select>
          <v-textarea
            v-model="novoEvento.descricao"
            label="Descrição do Evento"
            outlined
            dense
            rows="3"
            required
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" @click="emitirEvento" :disabled="!isFormValid || loading">
          <v-progress-circular
            v-if="loading"
            indeterminate
            size="20"
            color="white"
          ></v-progress-circular>
          <span v-else>Salvar</span>
        </v-btn>
        <v-btn color="grey" @click="fecharModal" :disabled="loading">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    modalAberto: Boolean,
    novoEvento: Object,
    eventosList: Array,
    loading: Boolean,
  },
  computed: {
    isFormValid() {
      return this.novoEvento.evento_id && this.novoEvento.descricao;
    },
  },
  methods: {
    formatarData(data) {
      const date = new Date(data);
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
      return date.toLocaleDateString("pt-BR", { day: "2-digit", month: "long" });
    },
    emitirEvento() {
      this.$emit("add-evento");
    },
    fecharModal() {
      this.$emit("fechar-modal");
    },
  },
};
</script>
