<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'gestoesDeAulas',
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Plano Mensal
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green lighten-1" outlined>
          <v-card-text>
            <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
            <h4>Disciplina: {{ disciplinaDescricao }}</h4>
            <h4>
              Professor:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[carregando...]" }}
            </h4>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12 text-center" class="pt-2">
        <template>
          <v-toolbar>
            <v-tabs v-model="mesSelecionado" background-color="primary" grow>
              <v-tab
                v-for="item in $constants.meses"
                :key="item.id"
                :value="item.id"
                @click="selectPlano(item.id)"
              >
                <v-badge v-if="form.mes_id === item.id" color="white">
                  {{ item.descricao }}
                </v-badge>
                <div v-else>{{ item.descricao }}</div>
              </v-tab>
            </v-tabs>
          </v-toolbar>
        </template>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(createOrUpdate)" :disabled="submittingForm">
        <v-row>
          <!-- Questoes form -->
          <v-col cols="12" v-for="plano in planoQuestoes" :key="plano.form">
            <ValidationProvider :name="plano.descricao" rules="required" v-slot="{ errors }">
              <e-label>{{ plano.descricao }}</e-label>
              <v-textarea solo dense v-model="form[plano.form]" :error-messages="errors" />
            </ValidationProvider>
          </v-col>
          <!-- Devolutiva -->
          <v-col cols="12">
            <e-label>Devolutiva</e-label>
            <v-textarea
              solo
              dense
              v-model="form.devolutiva"
              :disabled="!$can(['planosMensais.devolutiva'])"
            />
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-btn
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
            >
              Enviar Dados
            </v-btn>
            <v-btn color="success" class="ml-3" @click="abrirModalExportar()">
              Exportar plano
            </v-btn>
          </v-row>
        </v-col>
      </form>
    </ValidationObserver>

    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="800px">
        <v-card>
          <v-card-title class="primary">
            <span style="color: #fff" class="text-h5">Exportar Plano</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-container>
              <ValidationObserver v-slot="{ handleSubmit }">
                <form @submit.prevent="handleSubmit(exportarPlano)" :disabled="submittingForm">
                  <v-row>
                    <v-col class="pt-4 pb-0" cols="12">
                      <e-label class="font-weight-medium">Turma</e-label>
                      <p class="font-weight-regular">
                        <v-select
                          :items="turmasGestoes"
                          item-text="turma.descricao"
                          item-value="id"
                          return-object
                          label="Selecione o campo abaixo"
                          v-model="gestaoTurmaSelecionada"
                        ></v-select>
                      </p>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" v-for="plano in planoQuestoes" :key="plano.form">
                      <e-label>{{ plano.descricao }}</e-label>
                      <v-text-field solo dense v-model="form[plano.form]" readonly />
                    </v-col>
                  </v-row>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn style="color: #fff" class="red darken-1" @click="dialog = false">
                      Fechar
                    </v-btn>
                    <v-btn
                      :disabled="submittingForm"
                      :loading="submittingForm"
                      type="submit"
                      style="color: #fff"
                      class="green darken-1"
                      >Salvar</v-btn
                    >
                  </v-card-actions>
                </form>
              </ValidationObserver>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>

      <e-modal-confirm
        :confirmDialog="confirmDialog"
        :objectModal="objectModal"
        @changeModalConfirm="enableSubmit"
      >
      </e-modal-confirm>
    </v-row>

    <v-alert
      v-show="formRequest"
      class="my-2 animate__animated animate__headShake"
      dense
      border="left"
      type="warning"
    >
      Preencha todo o questionário
    </v-alert>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    form() {
      this.formRequest = false;
    },
  },
  async mounted() {
    await this.loadData();
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
    gestaoDeAulaId() {
      return this.$route.params.gestaoDeAulaId;
    },
    disciplinaId() {
      return this.$route.params.disciplinaId;
    },
    disciplinaDescricao() {
      if (this.gestaoDeAula?.disciplina) {
        return this.gestaoDeAula.disciplina.descricao;
      }
      const disciplinaEncontrada = this.gestaoDeAula.disciplinas.find(
        (disciplina) => disciplina.id === parseInt(this.disciplinaId, 10)
      );
      return disciplinaEncontrada ? disciplinaEncontrada.descricao : "Sem disciplina";
    },
  },
  data() {
    return {
      submittingForm: false,
      formRequest: false,
      loadForm: false,
      form: {
        mes_id: 1,
        disciplina_id: null,
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
        disciplinas: [],
      },
      mesSelecionado: 0,
      turmasGestoes: [],
      gestaoTurmaSelecionada: {
        gestaoDeAulaId: null,
      },
      dialog: false,
      enableSubmit: true,
      confirmDialog: false,
      objectModal: {
        title: "Exportar plano",
        message: "Tem certeza que deseja exportar esta plano?",
      },
      planoQuestoes: [],
      formExportarPlano: {
        instrutorDisciplinaTurma_id: null,
        mes_id: null,
        disciplina_id: null,
        tematica: "",
        objeto_conhecimento: "",
        habilidades: "",
        metodologia: "",
        processos_avaliativos: "",
        recursos_didaticos: "",
      },
    };
  },
  methods: {
    setForm() {},
    async selectPlano(mes) {
      this.loadForm = true;
      try {
        const response = await this.$services.planosBimestraisService.planoMensal(
          this.gestaoDeAulaId,
          this.disciplinaId,
          mes
        );
        this.loadForm = false;
        if (response !== "") {
          this.form = response;
          return;
        }
        this.form = {
          mes_id: mes,
        };
      } catch (error) {
        this.$handleError(error);
      }
      this.loadForm = false;
    },
    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      this.formRequest = false;
      try {
        await this.$constants.planosMensaisForm.map((item) => {
          if (!this.form[item.form] || this.form[item.form] === "") {
            this.formRequest = true;
          }
          return item;
        });
        if (this.formRequest) {
          this.$loaderService.close();
          return;
        }
        this.form.instrutorDisciplinaTurma_id = this.gestaoDeAulaId;
        this.form.disciplina_id = this.disciplinaId;
        this.form.mes_id = this.mesSelecionado + 1;
        await this.$services.planosBimestraisService.planoMensalCreateOrUpdate(this.form);
        this.$toast.success("Plano mensal salvo com sucesso!");
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async loadData() {
      await this.carregarQuestoes();

      try {
        this.$loaderService.open("Carregando dados...");
        const gestaoResponse = await this.$services.gestoesDeAulasService.visualizarParaPlano(
          this.gestaoDeAulaId
        );
        this.gestaoDeAula = gestaoResponse.gestoesDeAulas;

        const response = await this.$services.planosBimestraisService.planoMensal(
          this.gestaoDeAulaId,
          this.disciplinaId,
          this.mesSelecionado + 1
        );

        if (response !== "") {
          this.form = response;
        }
        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    async exportarPlano() {
      this.$loaderService.open("Exportando plano...");
      try {
        this.formExportarPlano.instrutorDisciplinaTurma_id = this.gestaoTurmaSelecionada.id;
        this.formExportarPlano.disciplina_id = this.disciplinaId;
        this.formExportarPlano.mes_id = this.mesSelecionado + 1;
        this.formExportarPlano.tematica = this.form.tematica;
        this.formExportarPlano.objeto_conhecimento = this.form.objeto_conhecimento;
        this.formExportarPlano.habilidades = this.form.habilidades;
        this.formExportarPlano.metodologia = this.form.metodologia;
        this.formExportarPlano.processos_avaliativos = this.form.processos_avaliativos;
        this.formExportarPlano.recursos_didaticos = this.form.recursos_didaticos;

        await this.$services.planosBimestraisService.planoMensalExportar(this.formExportarPlano);

        this.$toast.success("Plano semestral exportado com sucesso!");

        this.dialog = false;
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },
    async carregarGestoesTurmas() {
      const response = await this.$services.turmasService.turmasGestaoSemelhantes(
        this.gestaoDeAulaId
      );
      this.turmasGestoes = response.gestoes;
    },

    async abrirModalExportar() {
      this.dialog = true;
      await this.carregarQuestoes();
      await this.carregarGestoesTurmas();
    },
    async carregarQuestoes() {
      this.planoQuestoes = this.$constants.planosMensaisForm;
    },
  },
};
</script>

<style></style>
