<template>
  <v-dialog v-model="modalConfirmacaoAberto" max-width="500px">
    <v-card>
      <v-card-title class="headline">Confirmar Remoção</v-card-title>
      <v-card-text> Deseja remover o evento "{{ eventoSelecionado?.descricao }}"? </v-card-text>
      <v-card-actions>
        <v-btn color="red" @click="confirmarRemocao" :disabled="loading">
          <v-progress-circular
            v-if="loading"
            indeterminate
            size="20"
            color="white"
          ></v-progress-circular>
          <span v-else>Remover</span>
        </v-btn>
        <v-btn color="grey" @click="fecharModal" :disabled="loading">Cancelar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    modalConfirmacaoAberto: Boolean,
    eventoSelecionado: Object,
    loading: Boolean,
  },
  methods: {
    confirmarRemocao() {
      this.$emit("confirmar-remocao");
    },
    fecharModal() {
      this.$emit("fechar-modal");
    },
  },
};
</script>
