import EventosCalendarioLetivoPage from "@/Pages/EventosCalendarioLetivo/index.vue";
import NewEditEventosCalendarioLetivoPage from "@/Pages/EventosCalendarioLetivo/NewEditEventosCalendarioLetivoPage.vue";
import CalendarioLetivoPage from "@/Pages/EventosCalendarioLetivo/EventosCalendarioLetivoPage.vue";
import ShowEventosCalendarioLetivoPage from "@/Pages/EventosCalendarioLetivo/ShowEventosCalendarioLetivoPage.vue";

export default [
  {
    path: "/eventos-calendario-letivo/",
    name: "eventos-calendario-letivo",
    component: EventosCalendarioLetivoPage,
  },
  {
    path: "/eventos-calendario-letivo/create",
    name: "eventos-calendario-letivo.create",
    component: NewEditEventosCalendarioLetivoPage,
    props: { editing: false },
  },
  {
    path: "/eventos-calendario-letivo/edit/:evento_id",
    name: "eventos-calendario-letivo.edit",
    component: NewEditEventosCalendarioLetivoPage,
    props: { editing: true },
  },
  {
    path: "/eventos-calendario-letivo/etapas/:evento_id",
    name: "eventos-calendario-letivo.etapas",
    component: CalendarioLetivoPage,
    props: { editing: true },
  },
  {
    path: "/eventos-calendario-letivo/show/:evento_id",
    name: "eventos-calendario-letivo.show",
    component: ShowEventosCalendarioLetivoPage,
    props: { editing: true },
  },
];
