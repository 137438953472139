import Model from "./Model";

export const SituacaoEventoCalendarioLetivoType = {
  ATIVA: 1,
  DESATIVADA: 0,
};

export default class EventoCalendarioLetivo extends Model {
  constructor(franquia) {
    super();
    Object.assign(this, franquia);
  }
}
