<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Selecione um Plano Mensal </v-card-title>

        <v-select
          :items="disciplinas"
          item-text="descricao"
          item-value="id"
          v-model="disciplinaSelecionada"
          return-object
          label="Selecione uma disciplina"
          solo
        ></v-select>

        <v-select
          :items="$constants.meses"
          item-text="descricao"
          item-value="id"
          v-model="mes"
          return-object
          label="Selecione um mes"
          solo
        ></v-select>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :dialog="dialog"
            @status="dialog = $event"
            color="blue darken-1"
            text
            @click="cancelar()"
            >Cancelar</v-btn
          >
          <v-btn color="blue darken-1" text @click="emitirGerarPlanoMensal">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    gestaoDeAulasId: {
      type: Number,
    },
    disciplinas: {
      type: Array,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
    typeModel: {
      type: String,
    },
  },

  watch: {
    dialog(val) {
      this.$emit("status", val);
    },
    typeModel(val) {
      this.type = val;
    },
  },
  data() {
    return {
      disciplinasArray: this.disciplinas,
      disciplinaSelecionada: null,
      disciplinasDaGestao: [],
      type: null,
      mes: null,
    };
  },
  methods: {
    emitirGerarPlanoMensal() {
      this.$emit(
        "gerar-plano-mensal",
        this.gestaoDeAulasId,
        this.disciplinaSelecionada.id,
        this.mes.id
      );
    },
    cancelar() {
      this.disciplinaSelecionada = null;
      this.bimestre = null;
      this.$emit("status", false);
    },
  },
};
</script>
