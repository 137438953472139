<template>
  <main-template>
    <v-container>
      <form @submit.prevent="handleSubmit(salvarEvento)" :disabled="submittingForm">
        <v-row>
          <v-col>
            <h1>
              <v-btn
                color="primary"
                x-small
                text
                @click="$router.push({ name: 'eventos-calendario-letivo' })"
              >
                <v-icon left>fa fa-angle-left</v-icon>
              </v-btn>
              Painel Administrativo - {{ editing ? "Editando" : "Criando" }} Eventos
            </h1>
          </v-col>
        </v-row>
        <v-card class="pa-4 mt-4">
          <v-form @submit.prevent="salvarEvento">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="formEvento.legenda"
                  label="Legenda"
                  outlined
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-color-picker v-model="formEvento.cor" hide-inputs></v-color-picker>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="formEvento.is_feriado"
                  label="Este evento é um Feriado?"
                ></v-checkbox>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  :disabled="submittingForm"
                  :loading="submittingForm"
                  type="submit"
                  block
                  >Enviar Dados</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </form>
    </v-container>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      evento: {
        legenda: "",
        cor: "#FF0000", // Cor padrão adicionada
        ano_id: null,
      },
      eventos: [],
      submittingForm: false,
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
    formEvento() {
      if (!this.eventos || !this.editing) return {};
      const { evento_id } = this.$route.params;
      const evento = this.eventos.filter((cir) => cir.id === parseInt(evento_id, 10)).shift();
      return { ...evento };
    },
  },
  mounted() {
    this.loadEventos();
    this.loadDataEvento();
  },
  watch: {
    user: {
      immediate: true,
      handler(newUser) {
        if (newUser && newUser.ano) {
          this.evento.ano_id = newUser.ano.id;
        }
      },
    },
  },
  methods: {
    async loadEventos() {
      try {
        const data = await this.$services.eventoCalendarioLetivoService.syncAll();
        this.eventos = data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async loadDataEvento() {
      try {
        if (this.eventos.length === 0) {
          this.$loaderService.open("Carregando dados");
          await Promise.all([this.loadEventos()]);
          this.$loaderService.close();
        }
        this.$forceUpdate();
      } catch (error) {
        this.$handleError(error);
      }
    },
    async salvarEvento() {
      this.submittingForm = true;

      if (!this.formEvento.legenda || this.formEvento.is_feriado === null) {
        this.$toast.danger("Por favor, informe a legenda e se o evento é um Feriado.");
        this.submittingForm = false;
        return;
      }

      const eventoParaSalvar = {
        ...this.formEvento,
        cor:
          typeof this.formEvento.cor === "object"
            ? this.formEvento.cor.hex
            : this.formEvento.cor || "#FF0000",
        legenda: this.formEvento.is_feriado === "Sim" ? "FERIADO" : this.formEvento.legenda,
      };
      console.log(this.formEvento);

      try {
        if (this.editing) {
          await this.$services.eventoCalendarioLetivoService.atualizarEvento(eventoParaSalvar);
          this.$toast.success("Evento editado com sucesso!");
        } else {
          await this.$services.eventoCalendarioLetivoService.criarEvento(eventoParaSalvar);
          this.$toast.success("Evento salvo com sucesso!");
        }
        this.$router.push({ name: "eventos-calendario-letivo" });
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.submittingForm = false;
      }
    },
  },
};
</script>

<style scoped>
.v-color-picker {
  max-width: 300px;
}
</style>
