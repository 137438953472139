<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'aulas',
                  params: { gestaoDeAulaId: $route.params.gestaoDeAulaId },
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Aula
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green" class="mb-5 d-flex" outlined>
          <v-card-text>
            <h4>Turma: {{ gestaoDeAula.turma.descricao }}</h4>
            <template v-if="!gestaoDeAula.is_polivalencia">
              <h4>Disciplina: {{ gestaoDeAula.disciplina?.descricao }}</h4>
            </template>
            <template v-else>
              <h4>Disciplinas:</h4>
              <div class="container">
                <div class="row">
                  <div v-for="(item, index) in gestaoDeAula.disciplinas" :key="index">
                    <v-chip class="ml-2" x-small>{{ item.descricao }} </v-chip>
                  </div>
                </div>
              </div>
            </template>
            <h4>
              Professor:
              {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[NÃO INFORMADO]" }}
            </h4>
            <h4>
              Turno:
              {{
                gestaoDeAula.turma.turno ? gestaoDeAula.turma.turno.descricao : "[NÃO INFORMADO]"
              }}
            </h4>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ValidationObserver v-if="etapas" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submitForm)" :disabled="submittingForm">
        <v-row>
          <v-col class="pt-0 pb-0" cols="6" v-if="etapas?.length">
            <ValidationProvider name="Etapa" rules="required" v-slot="{ errors }">
              <e-label>Etapas</e-label>

              <e-autocomplete
                v-if="editing"
                disabled
                :items="etapas"
                :return-object="false"
                :error-messages="errors"
                :item-text="
                  (item) =>
                    `${item.descricao} - Início: ${dateFormatConvert(
                      item.periodo_inicial
                    )} Fim: ${dateFormatConvert(item.periodo_final)}`
                "
                :item-value="(item) => item.id"
                v-model="etapaSelecionada"
                @change="() => verificarEtapaSelecionada()"
                label="Selecione um Etapa"
                dense
                solo
              />

              <e-autocomplete
                v-else
                :items="etapas"
                :return-object="false"
                :error-messages="errors"
                :item-text="
                  (item) =>
                    `${item.descricao} - Início: ${dateFormatConvert(
                      item.periodo_inicial
                    )} Fim: ${dateFormatConvert(item.periodo_final)}`
                "
                :item-value="(item) => item.id"
                v-model="etapaSelecionada"
                @change="() => verificarEtapaSelecionada()"
                label="Selecione um Etapa"
                dense
                solo
              />
            </ValidationProvider>
          </v-col>
          <v-col>
            <v-btn
              v-if="etapaBloqueada == true && etapaPendente == false"
              style="box-shadow: none"
              color="success"
              class="text-none mt-4"
              @click="() => criarPedido(etapaSelecionada)"
              >Solicitar Autorização</v-btn
            >
          </v-col>
        </v-row>
        <div v-if="etapaSelecionada && etapaBloqueada == false">
          <v-row>
            <v-col class="pt-0 pb-0" cols="6">
              <ValidationProvider name="Tipo de Aula" rules="required" v-slot="{ errors }">
                <e-label>Tipo de Aula</e-label>
                <e-autocomplete
                  :items="$constants.tiposDeAulas"
                  :return-object="false"
                  :error-messages="errors"
                  v-model="aulaSelecionada.tipo_de_aula"
                  @change="() => verificaTipoDaAula()"
                  label="Selecione um tipo de aula"
                  dense
                  solo
                />
              </ValidationProvider>
            </v-col>
          </v-row>

          <v-row v-if="mostrarCampos">
            <!-- Calendário -->
            <v-col class="pt-0 pb-0" cols="6">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="data"
                persistent
                width="450px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <e-label>Selecione uma data</e-label>
                  <v-text-field
                    v-model="aulaSelecionada.data"
                    persistent-hint
                    :disabled="!aulaSelecionada.tipo_de_aula"
                    label="Escolha uma data"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                    solo
                  ></v-text-field>
                </template>

                <v-card>
                  <v-container>
                    <v-row>
                      <!-- Calendário -->
                      <v-col cols="12">
                        <v-date-picker
                          v-model="data"
                          full-width
                          class="mt-4"
                          scrollable
                          :min="inicioPeriodoEtapa"
                          :max="fimPeriodoEtapa"
                          :allowed-dates="allowedDates"
                          :events="feriados.map((feriado) => feriado.data)"
                          :event-color="getEventColor"
                        >
                          <!-- Personalizar os dias do calendário -->
                          <template v-slot:day="{ date, day }">
                            <v-badge
                              :color="isFeriado(date) ? 'red' : ''"
                              :content="isFeriado(date) ? 'F' : ''"
                              overlap
                            >
                              <span>{{ day }}</span>
                            </v-badge>
                          </template>
                        </v-date-picker>
                      </v-col>

                      <!-- Lista de feriados ao lado -->
                      <v-col cols="12">
                        <v-card class="pa-2" outlined>
                          <v-list dense>
                            <v-list-item v-for="(feriado, index) in feriados" :key="index">
                              <!-- Ícone colorido representando o feriado -->
                              <v-list-item-icon>
                                <v-icon :color="feriado.cor">mdi-calendar</v-icon>
                              </v-list-item-icon>
                              <v-list-item-content>
                                <v-list-item-title class="text-caption">
                                  <!-- Exibe apenas o dia da data -->
                                  <strong>{{ formatarDia(feriado.data) }}:</strong>
                                  {{ feriado.descricao }}
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-container>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn text color="primary" @click="modal = false">Cancelar</v-btn>
                    <v-btn text color="primary" @click="$refs.dialog.save(data)">OK</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>

            <v-col class="pt-0 pb-0" v-if="aulaSelecionada.tipo_de_aula !== 'Aula Remota'" cols="6">
              <ValidationProvider name="Horário da aula" rules="required" v-slot="{ errors }">
                <e-label>Selecione um horário</e-label>
                <v-alert v-if="!gestaoDeAula.turma.turno_id" class="pa-1" type="error" border="left"
                  >Essa turma está sem turno cadastrado, por favor, edite a turma e selecione um
                  turno para ela.</v-alert
                >
                <e-autocomplete
                  v-else
                  :disabled="aulaSelecionada.data ? false : true"
                  :items="horarios"
                  :error-messages="errors"
                  label="Selecione uma opção"
                  :item-text="(item) => item.descricao"
                  v-model="horarioSelecionado"
                  return-object
                  dense
                  @change="() => pesquisaAulas()"
                />
              </ValidationProvider>
              <!-- @change="() => pesquisaAulas()" -->
            </v-col>

            <v-col
              cols="12"
              v-if="horarioSelecionado || aulaSelecionada.tipo_de_aula === 'Aula Remota' || editing"
            >
              <div cols="12" v-if="!editing">
                <div v-if="gestaoDeAula.is_polivalencia">
                  <e-label>Selecione as Disciplinas dessa Aula</e-label>
                  <div class="container">
                    <div class="row">
                      <v-checkbox
                        class="ml-2"
                        v-for="(item, index) in gestaoDeAula.disciplinas"
                        :key="index"
                        v-model="disciplinasMarcadas"
                        :label="item.descricao"
                        :value="item.id"
                      ></v-checkbox>
                    </div>
                  </div>
                </div>
              </div>
              <v-col cols="12" v-else>
                <div v-if="aulaSelecionada.disciplinas?.length">
                  <div class="row mb-2">
                    <e-label>Disciplinas dessa Aula</e-label>
                  </div>
                  <div class="container">
                    <div class="row">
                      <div v-for="(item, index) in aulaSelecionada.disciplinas" :key="index">
                        <v-chip x-small>{{ getNomeDisciplina(item) }} </v-chip>
                      </div>
                    </div>
                  </div>
                </div>
              </v-col>
              <template v-if="gestaoDeAula.turma.ano_id === 1">
                <e-label>Objeto de Conhecimento</e-label>
                <div v-if="!editing">
                  <v-card v-for="item in arrayObjetos" :key="item" class="mx-auto my-1 col-12">
                    <strong>{{ item.descricao }}: </strong>
                    <v-textarea solo dense v-model="objeto_de_conhecimento[item.id]" />
                  </v-card>
                </div>
                <div v-else>
                  <v-card
                    v-for="(item, index) in arrayObjetos"
                    :key="index"
                    class="mx-auto my-1 col-12"
                  >
                    <strong>{{ getNomeDisciplina(item.id) }}</strong>
                    <v-textarea
                      solo
                      dense
                      :value="item.content"
                      @change="editarConteudoPoli(index, $event)"
                    />
                  </v-card>
                </div>

                <ValidationProvider
                  v-if="outrosCampos"
                  name="Percursos"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <e-label>Percursos Metodológicos e Avaliativos</e-label>
                  <v-textarea
                    solo
                    dense
                    v-model="aulaSelecionada.percurso"
                    :error-messages="errors"
                  />
                </ValidationProvider>

                <ValidationProvider
                  v-if="outrosCampos"
                  name="Recursos"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <e-label>Recursos</e-label>
                  <v-textarea
                    solo
                    dense
                    v-model="aulaSelecionada.recursos"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </template>

              <template v-if="gestaoDeAula.turma.ano_id >= 2 && gestaoDeAula.is_polivalencia">
                <e-label>Conteúdos / Atividades Realizadas</e-label>
                <div v-if="!editing">
                  <v-card v-for="item in arrayObjetos" :key="item" class="mx-auto my-1 col-12">
                    <strong>{{ item.descricao }}: </strong>
                    <ValidationProvider
                      name="Conteúdos / Atividades Realizadas"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-textarea
                        solo
                        dense
                        :error-messages="errors"
                        required
                        v-model="conteudos_atividades[item.id]"
                      />
                    </ValidationProvider>
                  </v-card>
                </div>
                <div v-else>
                  <v-card
                    v-for="(item, index) in arrayObjetos"
                    :key="item.id || index"
                    class="mx-auto my-1 col-12"
                  >
                    <strong>{{ getNomeDisciplina(item.id || item) }}</strong>
                    <ValidationProvider
                      :name="`conteudo-${item.id || index}`"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <v-textarea
                        solo
                        dense
                        :error-messages="errors"
                        :value="item.content"
                        @input="(val) => editarConteudoPoli(index, val)"
                        required
                      />
                    </ValidationProvider>
                  </v-card>
                </div>
              </template>

              <template v-if="gestaoDeAula.turma.ano_id >= 2 && !gestaoDeAula.is_polivalencia">
                <e-label>Conteúdos / Atividades Realizadas</e-label>
                <ValidationProvider
                  name="Conteúdos / Atividades Realizadas"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <v-textarea
                    solo
                    dense
                    v-model="aulaSelecionada.conteudos_atividades"
                    :error-messages="errors"
                  />
                </ValidationProvider>
              </template>

              <v-btn
                v-if="outrosCampos"
                color="primary"
                :disabled="submittingForm"
                :loading="submittingForm"
                type="submit"
                >Enviar Dados</v-btn
              >
              <v-btn
                v-if="editing && outrosCampos"
                class="ml-3"
                color="success"
                @click="dialog.duplicar = true"
                >Duplicar Aula</v-btn
              >
              <v-btn
                v-if="editing && outrosCampos"
                class="ml-3"
                color="success"
                @click="dialog.exportar = true"
                >Exportar Aula</v-btn
              >
            </v-col>
          </v-row>
        </div>
        <v-row v-if="etapas?.length === 0 && etapaBloqueada == false">
          <v-col cols="12">
            <v-alert type="warning" border="left">
              Não existem etapas disponíveis para a data atual
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="etapaBloqueada == true && etapaPendente == false">
          <v-col cols="12">
            <v-alert :class="avisoAnime" type="warning" border="left">
              Atenção essa etapa está bloqueada, para lançar aulas você deve solicitar uma
              autorização, clicando no botão acima
            </v-alert>
          </v-col>
        </v-row>
        <v-row v-if="etapaPendente == true">
          <v-col cols="12">
            <v-alert :class="avisoAnime" type="warning" border="left">
              Aguarde, já existe um pedido de autorização para essa etapa e estamos analisando.
            </v-alert>
            <v-btn class="success" @click="verPedidos">Acompanhe seus pedidos</v-btn>
          </v-col>
        </v-row>

        <div v-if="etapas?.length > 0 && etapaBloqueada == false && etapaSelecionada">
          <v-row
            v-if="!aulaSelecionada.tipo_de_aula || !aulaSelecionada.data || !horarioSelecionado"
          >
            <v-col cols="12" v-if="aulaSelecionada.tipo_de_aula !== 'Aula Remota'">
              <v-alert type="warning" border="left"> Por favor, preencha todos os campos! </v-alert>
            </v-col>
          </v-row>
        </div>
      </form>

      <v-bottom-sheet v-model="aulasEmConflitos" scrollable>
        <v-sheet class="text-center pa-md-8 mx-lg-auto" height="auto">
          <v-btn color="error" dark small @click="aulasEmConflitos = !aulasEmConflitos">
            Fechar
          </v-btn>

          <h2 class="mt-4">Aulas conflitadas</h2>

          <v-simple-table class="mt-4" style="border: 1px solid #e0e0e0">
            <template>
              <thead style="background-color: #e0e0e0">
                <tr>
                  <th class="text-left">#</th>
                  <th class="text-left">Professor</th>
                  <th class="text-left">Situação</th>
                  <th class="text-left">Data/Horario</th>
                  <th class="text-left">Turma</th>
                  <th class="text-left">Disciplina</th>
                </tr>
              </thead>
              <tbody class="text-left">
                <tr v-for="item in aulasConflitadas" :key="item.id">
                  <td>{{ item.id }}</td>
                  <td>
                    {{ item.professor.nome }}
                    <br />
                    <v-chip color="grey" dark x-small>
                      {{ item.turma?.franquia?.descricao }}
                    </v-chip>
                  </td>
                  <td>{{ item.situacao ? item.situacao : "Aguardando" }}</td>
                  <td>
                    <span>
                      {{ item.data.split("-").reverse().join("/") }}
                    </span>
                    - {{ item.horario.descricao }}
                  </td>
                  <td>{{ item.turma?.descricao }}</td>
                  <td>{{ item.disciplina?.descricao }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-sheet>
      </v-bottom-sheet>
    </ValidationObserver>
    <v-alert color="orange" icon="warning" class="text-caption" v-else
      >Atenção, essa turma ainda não foi vinculada a um circuito, para fazer isso você tem que ir em
      Turmas->Editar e Selecione o Circuito desejado, caso seja um professor aguarde o responsável
      realizar esse procedimento.</v-alert
    >
    <e-modal-duplicar-aula
      v-if="aulaSelecionada.disciplinas"
      :submittingForm="submittingForm"
      :dialog="dialog.duplicar"
      :gestaoDeAulas="aulaSelecionada"
      :form="{ ...aulaSelecionada }"
      :horarios="horarios"
      :disciplinas="aulaSelecionada.disciplinas"
      @dialogChange="dialog.duplicar = $event"
    >
    </e-modal-duplicar-aula>
    <e-modal-duplicar-aula
      v-else
      :submittingForm="submittingForm"
      :dialog="dialog.duplicar"
      :gestaoDeAulas="aulaSelecionada"
      :form="{ ...aulaSelecionada }"
      :horarios="horarios"
      @dialogChange="dialog.duplicar = $event"
    >
    </e-modal-duplicar-aula>
    <e-modal-exportar-aula
      :submittingForm="submittingForm"
      :dialog="dialog.exportar"
      :form="{ ...aulaSelecionada }"
      @dialogChange="dialog.exportar = $event"
      :horarios="horarios"
    >
    </e-modal-exportar-aula>
  </main-template>
</template>

<script>
import ELabel from "../../components/ELabel.vue";
import { dateFormatParam } from "../../plugins/filters";

export default {
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    aula_id: {
      type: Number,
      require: true,
    },
  },
  computed: {
    tipo_de_aula() {
      return this.aulaSelecionada.tipo_de_aula;
    },
    exibirOutrosCampos() {
      return this.gestaoDeAula.turma.ano_letivo <= 2024;
    },
    exibirConteudosAtividades() {
      return this.gestaoDeAula.turma.ano_letivo === 2025;
    },
  },
  mounted() {
    this.loadData();
    if (this.editing) {
      this.mostrarCampos = true;
      this.outrosCampos = true;
    }
    this.carregarFeriados();
  },
  data() {
    return {
      objetos: [],
      objeto_de_conhecimento: [],
      conteudos_atividades: [],
      arrayObjetos: [],
      conteudos: [],
      text: "",
      textColor: "#000000",
      disciplinasMarcadas: [],
      avisoAnime: "",
      etapaPendente: false,
      etapaBloqueada: false,
      idsEtapasDisponivels: [],
      idsEtapasPendentes: [],
      feriados: [],
      autorizacao: [],
      mostrarEtapas: false,
      fimPeriodoEtapa: null,
      inicioPeriodoEtapa: null,
      etapaSelecionada: null,
      feriados: [],
      etapas: null,
      turma: null,
      data: null,
      modal: false,
      aulasEmConflitos: false,
      aulasConflitadas: [],
      mostrarCampos: false,
      outrosCampos: false,
      submittingForm: false,
      form: {},
      aulaSelecionada: {
        data: null,
      },
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
      },
      aulaDuplicada: {},
      gestaoDeAulas: null,
      dialog: {
        duplicar: false,
        exportar: false,
      },
      horarios: [],
      horarioSelecionado: {
        descricao: "Escolha um horario",
      },
      arrayObjetoEditar: [],
    };
  },
  watch: {
    disciplinasMarcadas(val) {
      const arrayConvert = [];
      val.forEach((element) => {
        const valor = this.gestaoDeAula.disciplinas.filter((dado) => dado.id === element);
        arrayConvert.push(valor[0]);
      });
      this.atualizarTopicos(arrayConvert);
    },
    tipo_de_aula() {
      if (this.aulaSelecionada.tipo_de_aula === "Aula Remota") {
        this.aulaSelecionada.horario_inicial = null;
        this.aulaSelecionada.horario_final = null;
        this.horarioSelecionado = null;
      }
    },
    data(value) {
      const data_old = new Intl.DateTimeFormat("pt-BR", {
        timeZone: "UTC",
      }).format(new Date(value));
      this.aulaSelecionada.data = data_old.replaceAll("/", "/");
    },
  },
  methods: {
    getNomeDisciplina(id) {
      const dis = this.gestaoDeAula.disciplinas.filter((obj) => obj.id === id);
      return dis[0].descricao;
    },
    editarConteudoPoli(index, $event) {
      this.arrayObjetos[index] = $event;
    },
    getConteudoPolivalencia() {
      try {
        // Limpa os arrays antes de preencher
        this.arrayObjetoEditar = [];
        this.arrayObjetos = [];

        // Verifica se existem disciplinas e conteúdo
        if (this.aulaSelecionada.disciplinas && this.aulaSelecionada.conteudo_polivalencia) {
          const conteudos = JSON.parse(this.aulaSelecionada.conteudo_polivalencia);

          // Garante que temos um array de conteúdos válido
          if (Array.isArray(conteudos)) {
            this.aulaSelecionada.disciplinas.forEach((disciplinaId, index) => {
              // Cria o objeto mantendo o ID mesmo se o conteúdo for vazio
              const obj = {
                id: disciplinaId,
                content: conteudos[index] || "", // Fallback para string vazia
                descricao: this.getNomeDisciplina(disciplinaId), // Adiciona a descrição
              };

              this.arrayObjetos.push(obj);
            });
          }
        }
      } catch (error) {
        console.error("Erro ao processar conteúdo polivalência:", error);
        // Inicializa vazio em caso de erro
        this.arrayObjetos = [];
      }
    },
    atualizarTopicos(array) {
      this.arrayObjetos = [];
      array.forEach((element) => {
        this.arrayObjetos.push(element);
      });
    },
    updateText(event) {
      this.text = event.target.innerHTML;
    },
    executeCommand(command) {
      document.execCommand(command, false, null);
    },

    changeFont(size) {
      document.execCommand("fontSize", false, "7");
      const sel = window.getSelection();
      if (sel.rangeCount) {
        const range = sel.getRangeAt(0);
        const node = document.createElement("span");
        node.style.fontSize = size;
        range.surroundContents(node);
      }
    },
    changeStyle(style) {
      document.execCommand(style, false, null);
      const sel = window.getSelection();
      if (sel.rangeCount) {
        const range = sel.getRangeAt(0);
        const node = document.createElement("span");
        node.style.fontStyle = style === "italic" ? style : null;
        node.style.fontWeight = style === "bold" ? style : null;
        node.style.textDecoration = style === "underline" ? style : null;
        range.surroundContents(node);
      }
    },
    setTextColor() {
      this.executeCommand("foreColor", this.textColor);
    },
    animarAviso() {
      this.avisoAnime = "animate__animated animate__headShake";
      setTimeout(() => (this.avisoAnime = ""), 500);
    },
    dateFormatConvert(data) {
      return dateFormatParam(data);
    },
    verificarEtapaSelecionada() {
      this.animarAviso();
      this.inicioPeriodoEtapa = this.etapas
        .filter((d) => d.id === this.etapaSelecionada)
        .shift().periodo_inicial;
      this.inicioPeriodoEtapa = dateFormatParam(this.inicioPeriodoEtapa, "y-m-d");
      this.fimPeriodoEtapa = this.etapas
        .filter((d) => d.id === this.etapaSelecionada)
        .shift().periodo_final;
      this.fimPeriodoEtapa = dateFormatParam(this.fimPeriodoEtapa, "y-m-d");

      const etapaVerificar = this.etapas.filter((d) => d.id === this.etapaSelecionada).shift();

      this.verificarSeEtapaEstaDisp(etapaVerificar);
    },
    async pesquisaAulas() {
      const data_aula = this.aulaSelecionada.data.split("/").reverse().join("-");
      // /* eslint-disable */
      const { aula_id } = this.$route.params;
      const horario_id = this.horarioSelecionado.id;
      const turma_id = this.gestaoDeAula.turma.id;
      if (!data_aula || !this.horarioSelecionado || !this.aulaSelecionada) return;

      const response = await this.$services.aulasService.pesquisarAulasEmConflitos(
        data_aula,
        horario_id,
        turma_id,
        aula_id
      );

      if (response.data?.length === 0) {
        this.mostrarCampos = true;
        this.outrosCampos = true;
      } else {
        this.aulasConflitadas = response.data;
        this.aulasEmConflitos = true;
        this.outrosCampos = false;
      }
    },
    async verificaTipoDaAula() {
      if (this.aulaSelecionada.tipo_de_aula === "Aula Remota" || this.editing) {
        this.getAula();
        this.mostrarCampos = true;
        this.outrosCampos = true;
        return;
      }
      await this.getHorarios();
      this.mostrarCampos = true;
      this.outrosCampos = false;
    },
    async submitForm() {
      if (this.editing) {
        const arrayParaEditar = this.arrayObjetos;
        arrayParaEditar.forEach((element, index) => {
          if (typeof element !== "string") {
            arrayParaEditar[index] = element.content;
          }
        });
        this.aulaSelecionada.conteudo_polivalencia = JSON.stringify(arrayParaEditar);
      } else {
        if (this.objeto_de_conhecimento.some((elemento) => elemento !== null)) {
          const arr = [];
          this.disciplinasMarcadas.forEach((element) => {
            arr.push(this.objeto_de_conhecimento[element]);
          });

          this.aulaSelecionada.conteudo_polivalencia = JSON.stringify(arr);
        }

        if (this.conteudos_atividades.some((elemento) => elemento !== null)) {
          const arr = [];
          this.disciplinasMarcadas.forEach((element) => {
            arr.push(this.conteudos_atividades[element]);
          });

          this.aulaSelecionada.conteudo_polivalencia = JSON.stringify(arr);
        }

        this.aulaSelecionada.disciplinas = this.disciplinasMarcadas;
      }

      this.aulaSelecionada.horario_inicial = this.horarioSelecionado
        ? this.horarioSelecionado.inicio
        : null;
      this.aulaSelecionada.horario_final = this.horarioSelecionado
        ? this.horarioSelecionado.final
        : null;
      this.aulaSelecionada.horario_id = this.horarioSelecionado ? this.horarioSelecionado.id : null;
      this.aulaSelecionada.etapa_id = this.etapaSelecionada;

      this.submittingForm = true;
      try {
        if (this.editing) {
          this.aulaSelecionada.instrutorDisciplinaTurma_id = parseInt(
            this.$route.params.gestaoDeAulaId,
            10
          );
          this.aulaSelecionada.data = this.aulaSelecionada.data.split("/").reverse().join("-");
          this.aulaSelecionada.etapa_id = this.aulaSelecionada.etapa_id.id;
          await this.$services.aulasService.atualizar(this.aulaSelecionada);
          this.$toast.success("Aula editada com sucesso!");
          this.$router.back();
        } else {
          if (this.disciplinasMarcadas.length === 0 && this.gestaoDeAula.is_polivalencia) {
            this.$toast.danger("Selecione pelo menos uma disciplina para continuar.");
            return;
          }

          this.aulaSelecionada.instrutorDisciplinaTurma_id = parseInt(
            this.$route.params.gestaoDeAulaId,
            10
          );
          this.aulaSelecionada.disciplina_id = this.gestaoDeAula.disciplina_id;
          this.aulaSelecionada.turma_id = parseInt(this.gestaoDeAula.turma_id, 10);
          this.aulaSelecionada.instrutor_id = parseInt(this.gestaoDeAula.instrutor_id, 10);
          this.aulaSelecionada.data = this.aulaSelecionada.data.split("/").reverse().join("-");
          await this.$services.aulasService.criarAula(this.aulaSelecionada);
          this.$toast.success("Aula cadastrada com sucesso!");
          this.$router.back();
        }
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.submittingForm = false; // Usando finally para garantir que sempre será resetado
      }
    },
    async getAula() {
      const { aula_id } = this.$route.params;
      const response = await this.$services.gestoesDeAulasService.listarAulasGestao(
        this.$route.params.gestaoDeAulaId
      );
      this.turma = response.payload.gestaoDeAula.turma;
      this.gestaoDeAula = response.payload.gestaoDeAula;
      this.horarios = response.payload.horarios.filter((horario) => {
        if (this.gestaoDeAula.is_polivalencia) {
          return horario.descricao;
        }
        return horario.descricao;
      });
      const aulaSelecionada = await this.$services.aulasService.getAula(parseInt(aula_id, 10));
      this.horarioSelecionado = aulaSelecionada.horario ?? null;

      this.text = aulaSelecionada.objeto_conhecimento;
      if (this.gestaoDeAula.relacoesDiasHorarios) {
        this.diasPermitidosParaCriarAula = this.gestaoDeAula.relacoesDiasHorarios.map(
          (g) => g.dia.id
        );

        this.horarios = this.gestaoDeAula.relacoesDiasHorarios.map((g) => g.horario);
        this.horariosSabado = this.horarios;
        this.horariosSabado = this.horariosSabado.map((h) => {
          return {
            id: h.id,
            turno_id: h.turno_id,
            descricao: h.descricao.split(" - ")[0],
            final: h.final,
            inicio: h.inicio,
          };
        });
        this.horariosDoComponente =
          new Date(this.data).getDay() === 5 ? this.horariosSabado : this.horarios;
      }
      return aulaSelecionada;
    },
    async loadData() {
      try {
        this.$loaderService.open("Carregando dados...");
        this.aulaSelecionada = await this.getAula();
        if (this.turma.circuito_nota_id) {
          const response = await this.$services.etapasService.syncAllEtapasDisponiveis(
            this.turma.circuito_nota_id,
            this.$route.params.gestaoDeAulaId
          );
          this.etapas = response.etapas;
          this.idsEtapasDisponivels = response.disponiveis;

          this.etapas.forEach((element) => {
            this.verificarSeTemPedido(element.id);
          });

          if (this.editing) {
            this.getEtapa();
          }
        }
        if (this.aulaSelecionada.data) {
          this.aulaSelecionada.data = this.aulaSelecionada.data.split("-").reverse().join("/");
        }
        this.$forceUpdate();

        if (this.editing) this.getConteudoPolivalencia();
      } catch (error) {
        this.$handleError(error);
      }
      this.$loaderService.close();
    },
    getEtapa() {
      let etapa = this.etapas.filter((d) => d.id === this.aulaSelecionada.etapa_id);
      etapa = etapa.shift();
      this.fimPeriodoEtapa = etapa.periodo_final;
      this.inicioPeriodoEtapa = etapa.periodo_inicial;
      this.etapaSelecionada = etapa;
    },
    criarPedido(etapaId) {
      this.$router.push({ name: "autorizacoes.create", params: { etapa: etapaId } });
    },
    verPedidos() {
      this.$router.push({ name: "autorizacoes" });
    },
    verificarSeEtapaEstaDisp(etapa) {
      if (this.idsEtapasDisponivels.includes(etapa.id)) {
        this.etapaBloqueada = false;
      } else {
        this.etapaBloqueada = true;
      }
      if (this.idsEtapasPendentes.includes(etapa.id)) {
        this.etapaPendente = true;
      } else {
        this.etapaPendente = false;
      }
    },
    async verificarSeTemPedido(etapaId) {
      const response = await this.$services.autorizacoesService.verificarPedidosDeferidos(etapaId);

      if (response.data.aprovados?.length) {
        this.idsEtapasDisponivels.push(etapaId);
      }

      if (response.data.pendentes?.length) {
        this.idsEtapasPendentes.push(etapaId);
      }
    },
    beforeDestroy() {
      this.text = this.$refs.editor.innerHTML;
    },
    allowedDates(val) {
      const diasPermitidos = [...this.diasPermitidosParaCriarAula];

      const dataFormatada = new Date(val).toISOString().split("T")[0];

      const feriadosFormatados = this.feriados.map(
        (feriado) => new Date(feriado.data).toISOString().split("T")[0]
      );

      return (
        diasPermitidos.includes(new Date(val).getDay()) &&
        !feriadosFormatados.includes(dataFormatada)
      );
    },
    async getHorarios() {
      const response = await this.$services.gestoesDeAulasService.listarAulasGestao(
        this.$route.params.gestaoDeAulaId
      );
      this.gestaoDeAula = response.payload.gestaoDeAula;

      if (this.gestaoDeAula.relacoesDiasHorarios) {
        this.diasPermitidosParaCriarAula = this.gestaoDeAula.relacoesDiasHorarios.map(
          (g) => g.dia.id
        );

        this.horarios = this.gestaoDeAula.relacoesDiasHorarios.map((g) => g.horario);
        this.horariosSabado = this.horarios;
        this.horariosSabado = this.horariosSabado.map((h) => {
          return {
            id: h.id,
            turno_id: h.turno_id,
            descricao: h.descricao.split(" - ")[0],
            final: h.final,
            inicio: h.inicio,
          };
        });
      }
      this.horariosDoComponente =
        new Date(this.data).getDay() === 5 ? this.horariosSabado : this.horarios;
    },
    async carregarFeriados() {
      try {
        const response = await this.$services.calendarioLetivoService.syncAll();
        this.feriados = response
          .filter((evento) => evento.evento.is_feriado == true)
          .map((evento) => ({
            data: evento.start,
            descricao: evento.descricao,
            cor: evento.color,
          }));
      } catch (error) {
        console.error("Erro ao carregar feriados:", error);
      }
    },
    formatarDia(data) {
      return data.split("-")[2];
    },
    getEventColor(date) {
      const feriado = this.feriados.find((f) => f.data === date);
      return feriado ? feriado.cor : "red"; // Define a cor padrão se não for um feriado
    },
  },
};
</script>

<style scoped>
.dataInput {
  background-color: white;
  width: 100%;
  height: 35px;
  box-shadow: 2px 2px rgb(0, 0, 0, 0.1);
}
.custom-alert.custom-alert {
  font-weight: bold !important;
  font-size: 14px !important;
}
.custom-alert .v-icon {
  color: black !important;
}
</style>
