import Axios from "@/plugins/Axios";
import store from "@/store";
import EventoCalendarioLetivo from "@/Models/EventoCalendarioLetivo";

export class EventoCalendarioLetivoService {
  async syncAll() {
    const currentUser = store.getters["Auth/user"];
    const response = await Axios().get(`evento-calendario-letivo?ano_id=${currentUser.ano_id}`);
    const eventos = response.data.map((evento) => new EventoCalendarioLetivo(evento));
    return eventos;
  }

  async criarEvento(evento) {
    const response = await Axios().post("evento-calendario-letivo", evento);
    const novoEvento = response.data;
    return novoEvento;
  }

  async atualizarEvento(evento) {
    const response = await Axios().put(`evento-calendario-letivo/${evento.id}`, evento);
    const eventoList = response.data;
    return eventoList;
  }

  async deletarEvento(evento) {
    const response = await Axios().delete(`evento-calendario-letivo/${evento.id}`);
    const eventoDeletada = response.data;
    return eventoDeletada;
  }

  async getCircuito(circuitoId) {
    const response = await Axios().get(`evento-calendario-letivo/${circuitoId}`);
    const result = response.data;
    return result;
  }
}
export default new EventoCalendarioLetivoService();
