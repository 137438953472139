<template>
  <main-template>
    <v-row>
      <v-col md="3" xs="6">
        <v-card color="secondary" :to="{ name: 'admin.usuarios.listar' }">
          <v-card-title>
            <v-icon large left> mdi-account-multiple </v-icon>
            <span class="title font-weight-light white--text">Usuários</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold white--text"> Listar usuários </v-card-text>
        </v-card>
        <v-card
          color="secondary"
          target="_blank"
          :href="`${apiHost}/super/criar-usuarios-para-todas-as-entidades`"
        >
          <v-card-title>
            <v-icon large left> mdi-account-multiple </v-icon>
            <span class="title font-weight-light white--text">Usuários</span>
          </v-card-title>
          <v-card-text class="subtitle font-weight-bold white--text"> Criar usuários </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </main-template>
</template>

<script>
export default {
  name: "AdminPage",
  data() {
    return {
      apiHost: process.env.VUE_APP_API_HOST,
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped></style>
